@import "./theme";

@font-face {
  font-family: "fontText";
  src: url("Assets/Bangers/Bangers-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "fontTextTitle";
  src: url("Assets/Bangers/Bangers-Regular.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "fontTitle";
  src: url("Assets/Salsa/Salsa-Regular.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "fontNormal";
  src: url("Assets/CarterOne/CarterOne-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/**
This file contains the general and shared style of your application.
 */
body {
  margin: 0;
  padding: 0;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  font-family: $fontDefault;
  background-image: url("./Assets/background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

input {
  font-family: $fontNormal;
  line-height: inherit;
  font-size: inherit;
  outline: none;
}

textarea,
select,
button {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  outline: none;
}

*:focus {
  outline: none;
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
