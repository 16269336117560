@import "../../theme";

/* The '&' is a shorthand for the containing element,
  so for example a '&.user-list' inside .menu will compile to
  .menu.user-list

  for more information visit https://sass-lang.com/guide
 */

.profile {
  //padding: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  height: 300px;

  img {
    width: auto;
    height: auto;
    object-fit: cover;
    display: block;
    border-radius: $borderRadius;
    max-width: 100%;
    max-height: 100%;
  }

  .profile-wrapper {
    @include centerColumn;
    background-color: $background_focus_orange;
    width: 60%;
    height: 100%;
    padding: 10px;
    //margin-right: 0.5em;
    box-shadow: $dropShadow;
    border-radius: $borderRadius;

    .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 100%;

      .picture {
        position: relative;
        display: flex;
        align-items: center;
        justify-self: center;
        border-radius: $borderRadius;
        width: 30%;
        padding: 0;

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: none;
        }
        &:hover .overlay {
          @include centerColumn;
        }
      }

      .details {
        border-radius: $borderRadius;
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 16px;
        padding-left: 10px;
        padding-right: 0px;

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          //margin: 0;
          //padding: 0;
          width: 100%;

          .label {
            margin-left: 0.5em;
            text-align: left;
          }
          .value {
            margin-right: 0.5em;
            text-align: right;
            font-family: $fontNormal;
          }
          .profile-input {
            border-radius: 0.75em;
            width: 50%;
            background-color: transparent;
            font-family: $fontNormal;
          }
          .stats-value {
            margin-right: 0.5em;
            text-align: right;
            font-family: $fontNormal;
            font-size: 14px;
          }
        }
      }
    }

    .button-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      .delete-button {
        margin-top: 15px;
        background: #ad3a38;
      }
    }

    .editButton {
      width: 100%;
    }
  }
  .invitations-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: $background_focus_blue;
    width: 40%;
    max-height: 300px;
    border-radius: $borderRadius;
    box-shadow: $dropShadow;
    overflow-y: auto;

    h1 {
      font-size: 1.5em;
      font-weight: 300;
      color: $textColor;
    }

    .list {
      display: flex;
      flex-direction: column;
      padding: 10px;
      align-items: flex-start;
      width: 100%;

      .friend-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 1em;

        .friend-picture {
          border-radius: $borderRadius;
          width: 30%;
          padding: 0;
          margin-right: 1em;
          margin-left: 0;
        }

        .value {
          @include centerColumn;
          margin-right: 0.5em;
          text-align: right;
          font-family: $fontNormal;
        }
      }
    }
  }

  .popup-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    @include center;

    .popup {
      position: relative;
      background-color: white;
      padding: 1em;
      border-radius: 10px;
      width: 50%;

      .close {
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;
        font-size: 34px;
        overflow: auto;
      }
      h2 {
        margin-top: 0;
      }

      .horizontal-list {
        display: flex;
        flex-direction: row;
        padding: 0;
        justify-content: space-around;
        width: 100%;

        .imageContainer {
          position: relative;
          display: flex;
          align-items: center;
          justify-self: center;
          border-radius: $borderRadius;
          width: 120px;
          height: 120px;
          padding: 0;
        }
      }
    }
  }
}

@media (min-width: $SMALL_WIDTH) and (max-width: $SMALL_LAPTOPS_WIDTH) {
  .profile {
    .profile-wrapper {
      .container {
        .picture {
          max-width: 80px;
        }

        .details {
          font-size: 14px;
          padding-left: 10px;
          padding-right: 0px;
          .item {
            margin: 0;
          }
        }
      }
    }
    .invitations-container {
      
      .list {
        .friend-container {
          font-size: 14px;
          .friend-picture {
            height: 50px;
            width: 50px;
          }
        }
      }
    }
  }
}
