@import "../../theme";

.character {
  &.container {
    border-color: white;
    border-radius: 0.75em;
    background-color: rgb(185, 117, 117);
    color: white;
    position: relative;
    width: 75%;
    height: 100%; // Set the height to 0 initially

    &.img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.fold {
      border-color: white;
      border-radius: 0.75em;
      background-color: burlywood;
      @include center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    &.highlight {
      border: 4px solid red; // or any other highlight style you want
    }


  }
  &.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
  }
  &:hover .overlay {
    @include centerColumn;
  }

  .character-button {
    &:hover {
      transform: translateY(-2px);
    }
    &:disabled {
      cursor: default;
      opacity: 0.4;
      &:hover {
        transform: none;
      }
    }
    font-size: 20px;
    @include center;
    text-align: center;
    height: 35px;
    border: transparent;
    cursor: pointer;
    opacity: 1;
    text-shadow: 0 0 15px #000; /* Black outline */
    background-color: transparent;
    transition: all 0.3s ease;
  }
}
