@import "../../theme";

/* The '&' is a shorthand for the containing element,
  so for example a '&.user-list' inside .friends will compile to
  .friends.user-list

  for more information visit https://sass-lang.com/guide
 */

.friends {
  color: black;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-height: 300px;

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    height: 50%;
    padding: 1px;

  .friends-container {
    background: $background_focus_orange;
    @include alignCenterColumn;
    justify-content: flex-start;
    flex:1;
    //width: 49%;
    max-height: 200px;
    border-radius: $borderRadius;
    box-shadow: $dropShadow;
    overflow-y: auto;
  }
  .requests-container {
    background: $background_focus_blue;
    @include alignCenterColumn;
    justify-content: flex-start;
    flex:1;
    //width: 49%;
    max-height: 200px;
    border-radius: $borderRadius;
    box-shadow: $dropShadow;
    overflow-y: auto;
  }

  .h1 {
    font-size: 1.5em;
    font-weight: 300;
    color: $textColor;
  }

  .list {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    align-items: flex-start;
    width: 100%;
  }

    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1em;
      

      .picture {
        height: 70px;
        
        flex:1;
        position: relative;
        background: lighten($background, 20%);
        border-radius: $borderRadius;
        //width: auto;
        padding: 0; // remove any padding
        margin-right: 1em;
        display: flex;
        justify-content: center;

        img {
          width: auto;
          height: auto;
          object-fit: cover;
          display: block;
          border-radius: $borderRadius;
          max-width: 100%;
          max-height: 100%;
      }
      }

      .value {
        display: flex;
        align-items: center;
        margin-right: 0.5em;
        color: black;
        text-align: right;
        font-family: $fontNormal;
      }
    }
  }

  .add-friend-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    width: 80%;

    .field {
      padding: 0;
      font-family: $fontNormal;
    }

    .input {
      background: white;
      width: 100%;
      border: solid;
      border-color: rgba(45, 44, 44, 0.653);
      border-radius: 0.75em;
      color: $textColor;
    }
  }
}
