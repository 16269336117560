@import "../theme";

.header {
  &.container {
    @include center;
    background-color: black;
    align-items: center;
    height: 6em;
  }

  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .title {
      font-weight: bold;
      font-family: $fontTitle;
      font-size: 60px;
      color: white;
      text-align: center;
      //flex:1;
    }
    img {
      width: 80px;
      height: 80px;
      max-width: 60px;
      max-height: 60px;
      margin-top: -12px;
      //flex:1;
    }
  }
}
