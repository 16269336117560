@import "../theme";

.login {
  &.container {
    margin-top: 1em;
    min-height: 200px;
    /* we include the center mixin defined in _theme.scss and save three duplicate lines :) */
    @include centerColumn;
  }
  &.form {
    @include justifyCenterColumn;
    width: 50%;
    max-width: 30em;
    height: 15em;
    font-size: 30px;
    font-weight: 300;
    padding-left: 6%;
    padding-right: 6%;
    border-radius: 0.75em;
    background-color: $background_transparent;
    transition: opacity 0.5s ease, transform 0.5s ease;
    box-shadow: $dropShadow;
  }
  &.field {
    @include justifyCenterColumn;
    margin-top: 15px;
    font-family: $fontNormal;
  }
  &.input {
    height: 50px;
    padding-left: 15px;
    margin-left: -4px;
    border: solid;
    border-color: rgba(45, 44, 44, 0.653);
    border-radius: 0.75em;
    margin-bottom: 20px;
    background: white;
    color: $textColor;
  }
  &.label {
    color: $textColor;
    margin-bottom: 10px;
    font-weight: 300;
  }
  &.h1 {
    @include center;
    font-family: $fontNormal;
    font-size: 45px;
    font-weight: 100;
  }
  &.button-form {
    @include justifyCenterColumn;
    width: 50%;
    max-width: 30em;
    height: 5em;
    font-size: 20px;
    font-weight: 400;
    border-radius: 0.75em;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  &.button-container {
    @include center;
    margin-top: 2em;
  }
}