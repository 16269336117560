@import "../theme";

/* The '&' is a shorthand for the containing element,
  so for example a '&.user-list' inside .menu will compile to
  .menu.user-list

  for more information visit https://sass-lang.com/guide
 */

.game {
  &.container {
    @include alignCenterColumn;
    height: 80vh;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      margin-top: 1em;
      margin-bottom: 1em;

      .instructions {
        @include centerColumn;
        background-color: transparentize(white, 0.2);
        border-radius: $borderRadius;
        padding: 10px;
        height: 100%;
        margin: 0;

        h1 {
          margin: 0;
          font-size: 30px;
          color: black;
        }
      }

      .help-button {
        font-size: 35px;
        width: 60px; 
        height: 60px; 
        border-radius: 50%;
      }
    }

    .instructions {
      @include centerColumn;
      background-color: transparentize(white, 0.2);
      border-radius: $borderRadius;
      padding: 10px;
      height: 10%;
      margin-top: 1em;

      h1 {
        font-size: 35px;
        color: black;
      }
    }

    .game {
      display: flex;
      justify-content: space-around;
      width: 100%;
      height: 100%;
      margin-bottom: 1em;
    }

    .character-button {
      &:hover {
        transform: translateY(-2px);
      }
      &:disabled {
        cursor: default;
        opacity: 0.4;
        &:hover {
          transform: none;
        }
      }
      font-size: 20px;
      @include center;
      text-align: center;
      height: 35px;
      border: transparent;
      cursor: pointer;
      opacity: 1;
      color: yellow;
      background-color: transparent;
      transition: all 0.3s ease;
    }
  }
}

@media (min-width: $SMALL_WIDTH) and (max-width: $SMALL_LAPTOPS_WIDTH) {
  .game {
    &.container {
      .header {
        .instructions {
          h1 {
            font-size: 25px;
          }
        }
      }

      .game {
        margin: 10px;
      }

      .character-button {
        font-size: 20px;
        height: 30px;
      }

      .button {
        .accept-character-button {
          font-size: 25px;
        }
      }
    }
  }

  h1 {
    font-size: 20px;
  }
}
