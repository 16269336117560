@import "../../theme";
.game-log {
  @include alignCenterColumn;
  background: $background_transparent;
  box-shadow: $dropShadow;
  height: 100%;
  width: 35%;
  border-radius: 0.75em;
  margin: 10px;
  margin-top: 0;

  .chat-log-input-container {
    @include centerColumn;
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .chat-log-button-container {
      display: flex;

    }
    .chat-log-send-button {
      margin-top: 10%;

      &:hover {
        transform: translateY(-2px);
      }
      &:disabled {
        cursor: default;
        opacity: 0.4;
        &:hover {
          transform: none;
        }
      }
      margin-left: 10px;
      padding: 10px 20px;
      font-weight: 700;
      font-size: 100%;
      background-color: black;
      border: transparent;
      border-radius: $borderRadius;
      color: white;
      cursor: pointer;
      transition: all 0.3s ease;
    }
  }

  &.chat {
    margin-top: 10px;
    width: 100%;
    height: 80%;
    background-color: $background_focus_white;
    display: inherit;
    align-items: baseline;
    padding: 10px;
    overflow-y: auto;
  }

  &.field {
    @include centerColumn;
    background: transparent;
    width: 100%;
    height: 20%;
  }

  &.input {
    @include center;
    height: 35px;
    width: inherit;
    border: solid;
    border-color: rgba(45, 44, 44, 0.653);
    margin-top: auto;
    margin-bottom: 10px;
    font-size: auto;
    background: white;
    color: $textColor;
    text-align: center;
    border-radius: 0.75em;
    font-family: $fontNormal;
  }
}
.text {
  @include center;
  padding: 2px;
  font-family: $fontNormal;
  font-size: 20px;
}