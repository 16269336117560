@import "../theme";

/* The '&' is a shorthand for the containing element,
  so for example a '&.user-list' inside .menu will compile to
  .menu.user-list

  for more information visit https://sass-lang.com/guide
 */

.menu {
  &.container {
    margin-top: 1em;
    background: $background_transparent;
    padding: 1.5em;
    border-radius: $borderRadius;
    @include alignCenterColumn;
    box-shadow: $dropShadow;
    width: 60%;
  }
  .view {
    background: transparent;
    padding: 0;
    border-radius: $borderRadius;
    width: 100%;
  }
  &.user-list {
    list-style: none;
    padding-left: 0;
  }
  &.user-item {
    @include centerColumn;
  }
  & p,h2 {
    margin: 0.5em 0;
  }
  .buttonbar {
    display: flex;
    flex-direction: row;
    width: 100%;
    .createLobby {
      flex: 3;
      //margin-right: 1em;
    }
    .join {
      flex: 4;
      //margin-right: 1em;
      display: flex;
      padding-left: 1em;
      padding-right: 1em;

      .input {
        flex:7;
        margin-right: 0.25em;
        @include center;
        border: solid;
        border-color: rgba(45, 44, 44, 0.653);
        background: white;
        color: $textColor;
        text-align: center;
        border-radius: 0.75em;
      }
      .join-button {
        flex:3;
      }
      
    }
    .logout {
      flex: 3;
    }
  }
  &.navbar {
    //background-color: #333;
    //background: lighten($background, 5%);
    width: 100%;
    margin: 1.5em;
    border-radius: $borderRadius;
    ul {
      background: $background_focus_white;
      
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      border-radius: $borderRadius;
      
      li {
        flex: 1;
        position: relative;
        
        &:not(:last-child) {
          border-right: 1px solid #ccc; // Add a border-right to all items except the last one
        }
        &.active {
          //color: blue; //doesn't seem to do anything rn
          font-weight: bold;
          background: $background_focus_blue;
          $borderRadius: $borderRadius;
        }

        a {
          display: block;
          text-decoration: none;
          text-align: center;
          font-size: larger;
          color: black;

          // nedim-j: doesn't work rn
          //color: #ccc; /* Default color for inactive tabs */
          //transition: color 0.3s ease;
          //&:hover { 
          //  color: #ff0000;
          //}
          
        }
        
      }
    }
  }
  
}


@media (min-width: $SMALL_WIDTH) and (max-width: $SMALL_LAPTOPS_WIDTH) {
  .menu {
    &.container {
      width: 70%;
    }
  }
}
