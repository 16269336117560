@import "../theme";



.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.loading-spinner {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 35px;

    & div {
        position: absolute;
        top: 5px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: $accent;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &:nth-child(1) {
            left: 6px;
            animation: loading-spinner1 0.6s infinite;
        }
        &:nth-child(2) {
            left: 6px;
            animation: loading-spinner2 0.6s infinite;
        }
        &:nth-child(3) {
            left: 26px;
            animation: loading-spinner2 0.6s infinite;
        }
        &:nth-child(4) {
            left: 45px;
            animation: loading-spinner3 0.6s infinite;
        }
    }
}
@keyframes loading-spinner1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes loading-spinner2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}
@keyframes loading-spinner3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

