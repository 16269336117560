@import "../theme";

/* The '&' is a shorthand for the containing element,
  so for example a '&.user-list' inside .lobby will compile to
  .lobby.user-list

  for more information visit https://sass-lang.com/guide
 */

.lobby {
  &.container {
    margin-top: 1em;
    background: $background_transparent;
    border-radius: $borderRadius;
    @include alignCenterColumn;
    box-shadow: $dropShadow;
    width: 50%;

    .input {
      margin-right: 0.25em;
      @include center;
      border: solid;
      border-color: rgba(45, 44, 44, 0.653);
      background: white;
      text-align: center;
      border-radius: 0.75em;
      width: 100%;
    }
  }
  &.user-list {
    list-style: none;
    padding-left: 0;
  }
  &.user-item {
    @include centerColumn;
  }
  & p,
  h2 {
    margin: 0.5em 0;
  }
  .buttonbar {
    display: flex;
    width: 100%;
  }

  .view {
    background: transparent;
    padding: 0;
    border-radius: $borderRadius;
    width: 100%;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      color: white;
      li {
        flex: 1;
        position: relative;
        //width: 33%;

        &:not(:last-child) {
          border-right: 1px solid #ccc; // Add a border-right to all items except the last one
        }
      }
    }

    .settings {
      @include alignCenterColumn;
      width: 100%;
      height: 100%;
    }

    .main {
      @include alignCenterColumn;
      .code-container {
        height: 2em;
        background: lighten($background, 100%);
        padding: 0;
        border-radius: $borderRadius;
        width: 100%;
        @include alignCenterColumn;
      }
      .code {
        margin: auto;
        max-width: $DESKTOP_WIDTH;
        color: $textColor;
        font-family: $fontNormal;
        font-weight: 1000;
        text-align: center;
      }
      .players {
        background: lighten($background, 100%);
        padding: 0;
        border-radius: $borderRadius;
        width: 100%;
        flex-direction: column;
        font-family: $fontNormal;
        li {
          margin: 10px;
          border-right: none;
        }
      }
      .button-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 1em;
        width: 100%;
      }
    }

    .friends-container {
      height: 100%;
      @include alignCenterColumn;

      .list {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        align-items: flex-start;
        width: 100%;

        .friend-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 1em;

          .friend-picture {
            width: 60px;
            
            flex: 1;
            position: relative;
            background: lighten($background, 20%);
            border-radius: $borderRadius;
            //width: auto;
            padding: 0; // remove any padding
            margin-right: 1em;
            display: flex;
            justify-content: center;

            img {
              width: auto;
              height: auto;
              object-fit: cover;
              display: block;
              border-radius: $borderRadius;
              max-width: 100%;
              max-height: 100%;
            }
          }

          .friend-value {
            display: flex;
            align-items: center;
            margin-right: 0.5em;
            color: black;
            text-align: right;
            font-family: $fontNormal;
          }
        }
      }
    }
  }
}

.lobby.button {
  width: 100%;
  margin-top: 10px;
  color: white;
  &.bottom {
    margin-bottom: 10px;
  }
}
.input {
  flex: 1;
  border-radius: 0.75em;
}

.popup-overlay {
  /*pop features */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  @include center;
}

.popup {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
}

.popup .close {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 34px;
}

.popup h2 {
  margin-top: 0;
}

.popup p {
  font-family: $fontNormal;
  margin-bottom: 20px;
}

.popup .close:hover {
  color: #666;
}
.empty-line {
  height: 20px;
}

@media (min-width: $SMALL_WIDTH) and (max-width: $SMALL_LAPTOPS_WIDTH) {
  .lobby {
    &.container {
      width: 70%;
      height: 100%;
    }
  }

  h1 {
    font-size: 20px;
  }
}
