@import "../theme";

.landingPage {
  &.container {
    margin-top: 1em;
    min-height: 250px;
    /* we include the center mixin defined in _theme.scss and save three duplicate lines :) */
    @include centerColumn;
  }
  &.form {
    @include justifyCenterColumn;
    width: 50%;
    max-width: 30em;
    height: 9em;
    font-size: 30px;
    font-weight: 300;
    padding-left: 6%;
    padding-right: 6%;
    border-radius: 0.75em;
    background: $background_transparent;
    transition: opacity 0.5s ease, transform 0.5s ease;
    box-shadow: $dropShadow;
  }
  &.field {
    @include center;
    flex-direction: column;
    margin-top: 1px;
    font-family: $fontNormal;
  }
  &.input {
    @include center;
    height: 35px;
    width: 66%;
    border: solid;
    border-color: rgba(45, 44, 44, 0.653);
    margin-left: 16.5%;
    margin-right: 16.5%;
    margin-top: 10px;
    background: white;
    color: $textColor;
    text-align: center;
    border-radius: 0.75em;
  }
  &.label {
    color: $textColor;
    margin-bottom: 10px;
    font-weight: 300;
    display: flex;
    justify-content: center;
  }
  &.button-form {
    @include justifyCenterColumn;
    width: 50%;
    margin-top:10px;
    font-size: 40px;
    font-weight: 400;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  &.button-container {
    @include center;
    margin-top: 40px;
  }
}