/*
  This file contains the most basic variables that control how your application will look.
 */

/* Theme Colors

   these values control the colors of your application.
   feel free to play around with these and see what other look you can come up with! :D
*/

$accent: #a4c8de;
$accentDark: #a4c8de;
$textColor: rgb(10, 9, 9);
$background: #ffffffee;
$background_transparent: transparentize($color: $background, $amount: 0.25);
$background_focus_white: transparentize($color: white, $amount: 0.5);
$background_focus_orange: transparentize($color: #ffe0b2, $amount: 0.2);
$background_focus_blue: transparentize($color: #c6e2ff, $amount: 0.2);

/* some constants */

$DESKTOP_WIDTH: 1160px;
$SMALL_LAPTOPS_WIDTH: 970px;
$TABLETS_WIDTH: 750px;
$SMALL_WIDTH: 768px;

/* some standard elements */
/* fonts */
$fontDefaultTile: "fontTextTitle", "sans-serif";
$fontTitle: "fontTitle", "salsa";
$fontDefault: "fontText", "sans-serif";
$fontNormal: "fontNormal", "sans-serif";

/* a standard drop shadow */
$dropShadow: 3px 3px 6px -2px rgba(0, 0, 0, 0.25);

$borderRadius: 0.75em;

/* mixins

  these allow you to create small snippets that you can insert with a one-liner in other stylesheets
  for an example, see styles/views/Login.scss
*/
@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin centerColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin alignCenterColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin justifyCenterColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button {
  color: #050f14;
}