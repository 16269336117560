@import "../theme";

.primary-button {
  &:hover {
    transform: translateY(-2px);
  }
  &:disabled {
    cursor: default;
    opacity: 0.4;
    &:hover {
      transform: none;
    }
  }
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  @include center;
  text-align: center;
  height: 40px;
  border: none;
  border-radius: $borderRadius;
  cursor: pointer;
  opacity: 1;
  color: $background;
  background-color: black;
  transition: all 0.3s ease;
}

@media (min-width: $SMALL_WIDTH) and (max-width: $SMALL_LAPTOPS_WIDTH) {
  .primary-button {
    font-size: 16px;
  }
}
