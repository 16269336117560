@import "../../theme";

/* The '&' is a shorthand for the containing element,
  so for example a '&.user-list' inside .menu will compile to
  .menu.user-list

  for more information visit https://sass-lang.com/guide
 */

.leaderboard {
  &.container {
    border-radius: $borderRadius;
    max-height: 300px;
    overflow-y: auto;
    
  }
  .user-list {
    font-family: $fontNormal;
    list-style: none;
    display: flex; // Added to make the list items flex containers
    justify-content: space-around; // Added to evenly distribute items horizontally
    flex-direction: column;
    width: 100%;
    gap: 10px;
    ul {
      margin: 0;
    }

  }
  .title-row{ 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //gap: 2em;
    align-items: center;
    text-align: center;
    margin-bottom: 0.5em;
    .header {
      text-align: left;
      flex: 1;
      font-size: 1.5em;
      font-weight: 500;
      color: $textColor;
      margin-right: -2em;
    }
    .titles {
      flex: 1;
      font-size: 1em;
      //font-weight: 70;
      color: $textColor;
    } 
    .sorting {
      width: 100%; // Added to ensure the sorting buttons span the whole width
      flex:1;
      display: flex; // Added to make the buttons flex containers
      justify-content: space-around; // Adjusted to evenly distribute buttons
      margin-top: 0.5em;
      margin-bottom: 0.5em; // Added some margin at the bottom for separation
      margin-right: 2.5em;
      .dropdown {
        width: 100%;
        flex:1;
      }
    } 
  }
  .user-item {
    @include centerColumn;
  }
   p,h2 {
    margin: 0.5em 0;
  }
}

.player {
  &.container {
    background: $background_focus_white;
    padding: 10px;
    border-radius: $borderRadius;
    display: flex;
    align-items: center;
    flex:1;
    flex-direction: row;
    justify-content:space-between;
    width: 100%;
    color: $textColor;

  }
  &.username {
    font-weight: 500;
    color: $accent;
    //width: 50%;
    flex: 2
  }
  .total-wins {
    flex: 1;
    text-align: center;
  }
  .total-played {
    flex: 1;
    text-align: center;
  }
  .win-percentage {
    flex: 1;
    text-align: center;
  }


}
.sorting {
  display:flex;
  flex-direction: row;
}